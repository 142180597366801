@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;600;700&family=Roboto:wght@300;400;500&display=swap");

body {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-family: Helvetica Neue, Helvetica, Arial Nova, Arial, sans-serif;
  background: #F0F0F0;
}

.NG-wrapper .NG-mainHeader .NG-topNav {
  border-bottom: 1px solid #E0E0E0;
}

.NG-wrapper .NG-mainHeader .NG-topNav .NG-businessCagegory {
  padding: 0;
  display: flex;
  margin-bottom: 0;
}

.NG-wrapper .NG-mainHeader .NG-topNav .NG-businessCagegory li {
  display: flex;
  border-left: 1px solid #E0E0E0;
  padding: 10px 15px;
  align-items: center;
}

.NG-wrapper .NG-mainHeader .NG-topNav .NG-businessCagegory li i {
  color: #79c3f0;
}

.NG-wrapper .NG-mainHeader .NG-topNav .NG-businessCagegory li p {
  font-size: 14px;
  font-weight: 700;
  list-style: none;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.42px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0;
  padding-left: 5px;
}

.NG-wrapper .NG-mainHeader .NG-topNav .NG-businessCagegory li p span {
  display: block;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.42px;
  font-family: "Roboto", sans-serif;
}

.NG-wrapper .NG-mainHeader .NG-topNav .NG-businessCagegory li:last-child {
  border-right: 1px solid #E0E0E0;
}

.NG-wrapper .NG-mainHeader .NG-topNav .NG-businessCagegory .NG-pointer {
  color: #79c3f0;
  font-size: 24px;
  margin: 0px 0px 4px 16px;
}

.NG-wrapper .NG-mainHeader .NG-topNav .NG-businessCagegory .NG-active {
  border-top: 2px solid #79c3f0;
}

.NG-wrapper .NG-mainHeader .NG-topNav .NG-businessCagegory .NG-active p {
  font-weight: 700;
}

.NG-wrapper .NG-fixedMenu {
  padding-top: 12px;
  border-bottom: 1px solid #E0E0E0;
  box-shadow: 0px 3px 10px #E0E0E0;
  padding-left: 12px;
  padding-right: 12px;
}

.NG-wrapper .NG-fixedMenu .NG-collapse {
  margin-left: 20px;
}

.NG-wrapper .NG-fixedMenu .NG-collapse .NG-navNav .nav-item {
  text-align:right;
}

.NG-wrapper .NG-fixedMenu .NG-collapse .NG-navNav .nav-item .nav-link span {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.42px;
  color: #001B86;
  max-width: 100px;
  text-align: center;
  margin: 0px 8px;
  height: 40px;
  display: flex;
  align-items: center;
}

.NG-wrapper .NG-fixedMenu .NG-collapse .NG-navNav .nav-item .nav-link i {
  text-align: center;
  color: #E0E0E0;
  display: block;
}

.NG-wrapper .NG-fixedMenu .NG-collapse .NG-navNav .nav-item .NG-greenBTN {
  color: #FFFFFF;
  border: none;
  outline: none;
  border-radius: 0px;
  background-color: #218808;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.34px;
  height: 44px;
  margin-top: 10px;
  margin-right: 15px;
  padding: 14px 16px;
}

.NG-wrapper .NG-fixedMenu .NG-collapse .NG-navNav .nav-item .NG-greenBTN i {
  color: #FFFFFF;
  margin-right: 5px;
}

.NG-wrapper .NG-fixedMenu .NG-collapse .NG-navNav .nav-item .NG-blueBTN {
  color: #FFFFFF;
  border: none;
  outline: none;
  border-radius: 0px;
  background-color: #001B86;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.34px;
  height: 44px;
  margin-top: 10px;
  padding: 14px 24px;
}

.NG-wrapper .NG-fixedMenu .NG-BrandBTN {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.42px;
  color: #001B86;
  max-width: 200px;
  text-align: center;
  margin: 0px 8px;
  height: 40px;
  display: flex;
  align-items: center;
}

.NG-wrapper .NG-fixedMenu .NG-collapse .NG-navNav .nav-item .NG-blueBTN i {
  color: #FFFFFF;
  margin-right: 5px;
}

.NG-wrapper .breadcrumb .NG-Obreadcrumb {
  padding: 15px 20px 0 5px;
}

.NG-wrapper .breadcrumb .NG-Obreadcrumb .breadcrumb-item a {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.42px;
  text-decoration: none;
  color: #001B86;
}

.NG-wrapper .breadcrumb .NG-Obreadcrumb .active {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.42px;
  text-decoration: none;
  color: #9E9E9E;
  margin-top: 4px;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion {
  border: none;
  /* min-width: 265px; */
  background: var(--Grey, #F0F0F0);
  position:relative;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion .NG-accordionHeader .collapsed {
  color: #54565A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.42px;
  background-color: transparent;
  box-shadow: inset 0 0px 0 rgba(0, 0, 0, 0);
}

.NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion .NG-accordionHeader .collapsed::after {
  background-image: none;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion .NG-accordionHeader .collapsed:focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0);
}

.NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion .NG-accordionHeader .accordion-button:not(.collapsed) {
  width: 210px;
  height: 44px;
  color: #001B86;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.42px;
  position: relative;
  background-color: #E1E1E1;
  box-shadow: inset 0 0px 0 rgba(0, 0, 0, 0);
  text-decoration: none;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion .NG-accordionHeader .accordion-button:not(.collapsed):hover {
  border-left: 2px solid #00aff0;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion .NG-accordionHeader .accordion-button:not(.collapsed)::after {
  background-image: none;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion .NG-accordionHeader .accordion-button:not(.collapsed)::before {
  content: "";
  position: absolute;
  right: -12px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 12px solid #E1E1E1;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion .NG-accordionHeader .accordion-button:not(.collapsed):focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0);
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem {
  border: 0px solid rgba(0, 0, 0, 0);
  background-color: #F0F0F0;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body {
  padding-top: 0px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  background: var(--Grey, #F0F0F0);
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body h2 {
  color: #54565A;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.72px;
  margin-bottom: 16px;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body label {
  color: #54565A;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.34px;
  margin-bottom: 10px;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body p {
  color: #54565A;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.34px;
  margin-bottom: 15px;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form {
  padding: 25px;
  padding-top: 10px;
  font-family: Helvetica Neue, Helvetica, Arial Nova, Arial, sans-serif;
  /* margin-top: 56px; */
}

/* .NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form h4 {
  color: #54565A;
  font-size: 17px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  margin-bottom: 32px;
} */
/* .NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet {
  margin-bottom: 32px;
} */
.NG-radiobutton-format {
  padding-top: 12px;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet label {
  color: #54565A;
  size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.54px;
  display: inline-block;
  margin-bottom: 5px;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet .NG-forminput {
  width: 100%;
  height: 50px;
  font-size: 14px;
  font-weight: 700;
  color: #54565A;
  padding-left: 15px;
  padding-right: 15px;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  border: 2px solid #D8D8D8;
  background-color: #FFFFFF;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet .NG-forminput:focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0);
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet .NG-formSelect {
  width: 100%;
  height: 50px;
  font-size: 14px;
  font-weight: 700;
  color: #54565A;
  font-style: normal;
  line-height: normal;
  border: 2px solid #D8D8D8;
  background-color: #FFFFFF;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet .NG-formSelect:focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0);
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet small {
  color: #54565A;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet small span {
  color: #001B86;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet .NG-flexyBox .form-check .form-check-input {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  border: 2px solid #D8D8D8;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet .NG-flexyBox .form-check .form-check-label {
  color: #54565A;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-block;
  margin: 8px;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSetBTN {
  justify-content: flex-end;
  display: flex;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSetBTN .NG-mainBTNs {
  border: 0;
  outline: 0;
  font-size: 16px;
  font-weight: 700;
  color: #FFFFFF;
  font-style: normal;
  text-align: center;
  line-height: normal;
  border-radius: 0;
  padding: 15px 32px;
  letter-spacing: 0.32px;
  background-color: #001B86;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSetBTN .NG-mainBTNs:focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0);
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-fileDisclamer {
  margin-top: 30px;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-fileDisclamer .NG-disclamer {
  font-size: 12px;
  color: #54565A;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
}

.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-fileDisclamer .NG-disclamer strong {
  font-size: 12px;
  color: #54565A;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
}

.NG-fieldSet {
  padding-top: 10px !important;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0.5rem;
  box-sizing: content-box;
}

.custom-table {
  width: 100% !important;
  border: 4px solid #d8d8d8;
}

.custom-table th {
  color: #53565a;
  font-family: Helvetica Neue, Helvetica, Arial Nova, Arial, sans-serif;
  font-weight: 700;
  background-color: #d8d8d8;
}

.custom-table tr {
  border-bottom: 4px solid #d8d8d8;
  background-color: #ffffff;
}

.custom-table tbody tr td {
  background-color: #ffffff;
}

.custom-table tfoot tr td {
  background-color: #f2f2f2;
}

.backtoselection {
  color: #001b86 !important;
  background-color: #d8d8d8 !important;
  border-color: #d8d8d8 !important;
  border-radius: 0px !important;
  outline: none;
}

a.backtoselection {
  text-decoration: none !important;
  width: 233px;
  margin-left: 8px;
  height: 56px;
  padding: 15px 30px;
  align-items: center;
  gap: 10px;
  font-weight: 700;
}

.excelBtn {
  color: #ffffff !important;
  background-color: #001b86 !important;
  border-color: #001b86 !important;
  border-radius: 0px !important;
  float: right;
  width: 233px;
  margin-left: 8px;
  height: 56px;
  padding: 10px 40px;
  align-items: center;
  gap: 10px;
  font-weight: 700;
}

a.no-href {
  color: blue;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

a.no-href:hover,
a:focus {
  color: #e78c07;
  text-decoration: none;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
  color: #001B86 !important;
  font-size: 16px;
  font-weight: 700;
  background-color: unset;
  border-color: unset;
}

.accordion-flush .accordion-item {
  border-bottom: 0;
  margin-bottom: 15px;
}

.accordion-button:focus {
  box-shadow: 0 0 0 0.10rem #00148c;
  border-radius: 10px;
}

.accordion-button:not(.collapsed)::after {
  transform: none;
  background: center center no-repeat url(../images/blue-carrot-up.svg);
  background-size: contain;
  content: '';
  height: 6px;
  position: absolute;
  right: 30px;
  top: 50%;
  width: 13px;
}

.accordion-button::after {
  background: center center no-repeat url(../images/blue-carrot-up.svg);
  background-size: contain;
  content: '';
  height: 6px;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: rotate(180deg);
  width: 13px;
}

.css-tj5bde-Svg {
  background: center center no-repeat url(../images/blue-carrot-up.svg);
  background-size: contain;
  content: '';
  height: 6px;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: rotate(180deg);
  width: 13px;
}

.css-1u9des2-indicatorSeparator {
  width: 0px !important;
}

.css-1jqq78o-placeholder {
  font-weight: bold;
  font-size: 14px !important;
  line-height: 17.09px !important;
}

.a-link-nounderline {
  color: #001B86 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  text-decoration-line: none !important;
}

.a-link-underline {
  color: #001B86 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  text-decoration-line: underline !important;
}

.a-link-with-underline::before {
  content: "\2022";
  /* Unicode character for bullet point */
  color: #001B86;
  font-size: 10px;
  /* Adjust as needed for your design */
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 5px;
  /* Adjust as needed for spacing between bullet and text */
}

.inline-container {
  display: inline-block;
}

.layout-container {
  /* padding-left: 24px;
  padding-right: 24px; */
  padding-bottom: 24px;
}

.padding-top {
  padding-top: 20px;
}

.padding-topWithLeft {
  padding-top: 20px;
  padding-left:0px;
}

.NG-headeAccordion {
  padding-top: 16px;
  padding-right: 22px;
}

.NG-wrapper .NG-accordion:first-child {
  padding-left: 12px;
  padding-right: 12px;
}
.NG-wrapper .NG-accordion .accordion  {
  padding-left: 0px;
  padding-right: 0px;
}

.account-warning {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  border: 1px solid #F9E24C;
  border-bottom: 3px solid #F9E24C;
  background: #FEFCE7;
  margin-bottom: 24px;
}

.account-warning-label {
  color: #000 !important;
  font-family: inherit;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  letter-spacing: 0.32px !important;
  margin-bottom: 0px !important;
}

@media (max-width: 768px) {
  .account-warning-label {
    color: #000 !important;
    font-family: inherit;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    letter-spacing: 0.32px !important;
    margin-bottom: 0px !important;
  }
}


.account-warning-img {
  width: 32px;
  height: 32px;
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 2px;
}

.NG-wrapper .NG-Loginform .NG-fieldSetBTN {
  justify-content: flex-end;
  display: flex;
}

.NG-wrapper .NG-Loginform .NG-fieldSetBTN .NG-mainBTNs {
  border: 0;
  outline: 0;
  font-size: 16px;
  font-weight: 700;
  color: #FFFFFF;
  font-style: normal;
  text-align: center;
  line-height: normal;
  border-radius: 0;
  padding: 15px 32px;
  letter-spacing: 0.32px;
  background-color: #001B86;
  width: 100%;
}

.NGbr-0 {
  border: none;
  border-radius: 0;
}

.bg-color-none {
  background: none !important;
}

.border-box-none {
  border: none !important;
  box-shadow: none !important;
}

.text-over-flow {
  max-width: 100px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mr-10 {
  margin-right: 10px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.label-text-heading {
  color: #54565A;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.32px;
}

.label-text {
  color: #54565A;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px;
  letter-spacing: 0.28px;
}

@media (max-width: 768px) {
    .NG-fieldSetBTN {     
      justify-content: center; 
    } 
} 
  
@media (max-width: 844px) {
     .NG-fieldSetBTN {     
       justify-content: center; 
    } 
}

@media (max-width: 768px) {
  .NG-fieldSet {     
    justify-content: center; 
  } 
} 

@media (max-width: 844px) {
   .NG-fieldSet {     
     justify-content: center; 
  } 
}

@media (max-width: 767px) {
  .form-check.me-5 {
    flex-basis: 100%;
    margin-right: 0px !important;
    display: flex;
    align-items: center;
  }
  .form-check-label {
    font-size: 16px;
  }
  .form-check-label {
    font-size: 16px;
  }
  .NG-flexyBox {
    flex-wrap: wrap;
  }
}
 
@media (min-width: 768px) and (max-width: 1024px) {
  
  .form-check.me-5 {
    flex-basis: 100%;
    margin-right: 0px !important;
    display: flex;
    align-items: center;
}
  .form-check-label {
    font-size: 14;
  }
  .form-check-label {
    font-size: 16px;
  }
  .NG-flexyBox {
    flex-wrap: wrap;
  }
  .NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet .NG-flexyBox .form-check .form-check-input {
    width: 20px;
    height: 20px;
  }
  .marginR{
    margin-right: 6rem;
  }
}

@media(min-width: 380px) and (max-width: 884px)  {
  .NG-mainBTNs {
    font-size: 16px;
    padding: 10px;
  }
  .submit-btn-container {
    display: flex;
    justify-content: center; 
  } 
  .NG-mainBTNs {
    width: 100%;
  }
  .NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet .NG-flexyBox .form-check .form-check-input {
    width: 20px;
    height: 20px;
  }
  .NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form {
    padding: 12px 12px;
  }


}

@media (min-width: 420px) and (max-width: 884px)  {
  .col-xl-12,
  .col-lg-12,
  .col-md-12,
  .col-sm-12,
  .col-12 {
    width: 100%; 
    padding-left: 0; 
    padding-right: 0;
  }
}
 
@media (min-width: 420px) and (max-width: 884px)  {
  .col-xl-8,
  .col-lg-8,
  .col-md-8,
  .col-sm-8,
  .col-8 {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
  }
  .col-xl-4,
  .col-lg-4,
  .col-md-4,
  .col-sm-4,
  .col-4 {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
  }
}

@media (max-width: 428px) {
  .NG-accordionItem {     
  width: 100% !important;
  } 

  .excelBtn,
  a.backtoselection {
    padding: 12px 18px; /* Adjusted padding */
    min-width: 160px; /* Adjusted min-width for both buttons */
  }

  .ng-text-right { 
    text-align: left;
    display: flex;
    justify-content: flex-start;
  }

  .paginatorLinks-bottom {
    height: 60px; /* Adjusted height */
  }
}
 
@media (min-width: 429px) and (max-width: 834px) {

  .NG-accordionItem {     
    width: 100% !important;
  } 

  .excelBtn {
    padding: 12px 22px;
    min-width: 180px; /* Adjusted min-width */
  }



  .paginatorLinks-bottom {
    height: 70px; /* Adjusted height */
  }
}
 
@media (min-width: 835px) and (max-width: 1180px) {

  .NG-accordionItem {     
    width: 100% !important;
  } 

 

  a.backtoselection {
    padding: 12px 22px;
    min-width: 190px; /* Adjusted min-width */
  }

  .ng-text-right { 
    text-align: left;
    display: flex;
    justify-content: flex-start;
  }

  .paginatorLinks-bottom {
    height: 75px;
  }

}


@media (min-width: 420px) and (max-width: 884px) {
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
  color: #001B86 !important;
  font-size: 12px;
  font-weight: 700;
  background-color: unset;
  border-color: unset;
}
}

@media (min-width: 380px) and (max-width: 767px) {
     .NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSetBTN .NG-mainBTNs { 
      border: 0;
      outline: 0;
      font-size: 10px;
      font-weight: 700;
      color: #FFFFFF;
      font-style: normal;
      text-align: center;
      line-height: normal;
      border-radius: 0;
      padding: 10px 10px;
      letter-spacing: 0.32px;
      background-color: #001B86;
  }
}

@media only screen   and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {  
   .NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSetBTN .NG-mainBTNs {
      font-size: 10px;
      padding: 10px 10px; 
    } 
    .NG-accordion.accordion{
      flex-direction: column;
    } 
}

/* Media query for iPhone 14 Pro Max */
@media only screen 
  and (device-width: 430px) 
  and (device-height: 932px) 
  and (-webkit-device-pixel-ratio: 3) {
    .NG-accordion.accordion{
      flex-direction: column;
    }
    .marginR{
      margin-right: 3rem;
    }
}


/***  Media queries for iPhone and iPads ***/

@media (min-width: 992px) and (max-width: 1199.98px) and (orientation: landscape) {
  #root .NG-wrapper .NG-mainHeader .NG-fixedMenu{
    padding-left: 0;
    padding-right:0;
  }
  .NG-accordionItem .accordion-body .layout-container .table-section {
    max-width:calc(100vw - 288px)
  }
}

@media (min-width: 768px) and (max-width: 991.98px) and (orientation: portrait) {

}

@media (min-width: 566px) and (max-width: 991.98px) {
  .NG-accordion .NG-headeAccordion .imgUserDiv .imgVectorDiv {
    height: 30px;
    width: 30px;
    float: none;
  }
  .NG-accordion .NG-headeAccordion .imgUserDiv .imgVectorDiv .imgVector {
    height: 14px;
    width: 14px;
    margin-left: 8px;
    margin-top: 3px;
  }
  .NG-accordion .NG-headeAccordion .imgUserDiv {
    padding-bottom: 26px;
    display: flex;
    align-items: center;
  }

  .NG-accordion .NG-headeAccordion .imgUserDiv  .user-name {
    font-size: 16px !important;
    line-height: 18px !important;
    padding-top: 0;
    padding-left: 0;
    margin-top:0 !important;
    margin-left:8px;
  }
  .NG-accordion .NG-headeAccordion .imgUserDiv{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  #root .NG-wrapper .NG-accordion:first-child{
    padding-left: 12px;
    padding-right: 12px;
  }
  #root .NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion .NG-accordionHeader .accordion-button{
    width:175px;
  }
  #root .NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion{
    min-width:200px;
    padding-right: 0;
  }
  #root .NG-wrapper .NG-mainHeader .navbar button.navbar-toggler.bg-dark{
    background-color: #fff !important;
  }
  #root .NG-wrapper .NG-mainHeader .NG-fixedMenu{
    padding-left: 0;
    padding-right:0;
  }
  .NG-wrapper .NG-fixedMenu .NG-collapse .NG-navNav .nav-item .nav-link span{
    font-size: 12px !important;
    line-height: 16px !important;
    height: auto !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  .NG-wrapper .NG-fixedMenu .NG-collapse .NG-navNav .nav-item .nav-link{
    padding:0;
  }
  .NG-wrapper .NG-fixedMenu .NG-collapse .NG-navNav .nav-item .nav-link span.logout-btn{
    padding: 6px 12px;
    height: 32px !important;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  .NG-wrapper .NG-fixedMenu .NG-collapse .NG-navNav .nav-item .nav-link .img-header-link {
    height: 16px;
    width: 16px;
  }
  .NG-wrapper .NG-fixedMenu .NG-collapse .NG-navNav .nav-item .nav-link .no-wrap-menuitem {
    display: flex;
    align-items: center;
    padding: 5px 10px;
  }
  .NG-accordionItem .accordion-body .layout-container .table-section {
    max-width:calc(100vw - 260px)
  }
}

@media (min-width: 360px) and (max-width: 565.98px) {
  #root .NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion .NG-accordionHeader .accordion-button{
    width:160px;
  }
  #root .NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion{
    min-width:175px;
    padding-right: 0;
  }
  #root .NG-wrapper .NG-accordion:first-child{
    padding-left: 16px;
    padding-right: 16px;
  }
  #root .NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body{
    padding-left: 0px !important;
  }
  #root .NG-wrapper .NG-mainHeader .navbar button.navbar-toggler.bg-dark{
    background-color: #fff !important;
  }
  #root .NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .layout-container{
    min-width: 265px;
    padding: 0px;
  }
  #root .NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .layout-container .form-div{
    padding:0;
  }
  #root .NG-wrapper .NG-mainHeader .NG-fixedMenu{
    padding-left: 0;
    padding-right:0;
  }
  #root .NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .layout-container  .ng-text-right a.backtoselection {
        padding: 12px 22px;
        min-width: 170px;
        font-size: 14px;
        margin-left: 0;
        margin-right: 0;
  }

  
  #root .NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .layout-container .ng-text-right { 
    text-align: left;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }
  #root .NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .layout-container .ng-text-right .excelBtn {
    padding: 12px 22px;
    min-width: 200px; /* Adjusted min-width */
    margin-left: 0;
    margin-top: 8px;
  }
  .paginatorLinks-top, .paginatorLinks-bottom {
    height:75px;
  }
  #root .NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .NG-accordion .NG-headeAccordion .imgUserDiv {
    display: flex;
    align-items: center;
  }
  .NG-accordion .NG-headeAccordion .imgUserDiv .user-name{
    font-size: 16px !important;
    line-height: 18px !important;
    padding-top: 0;
    padding-left: 0;
    margin-top:0 !important;
    margin-left:8px;
    min-width: 180px;
 }
}

#footer-control-container .ngcw-footer__container #google_translate_element .ngcw-footer-secondary a, #footer-control-container .ngcw-footer__container #google_translate_element .ngcw-footer-secondary .skiptranslate > span {
  display: none;
}

a[href='https://translate.google.com']{
  display: none !important;
}

.paddingRight-0 {
  padding-right: 0px !important;
}

.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
}

.popup-header h2 {
  margin: 0 0 10px;
}

.value-container {
  padding: 0 25%;
  text-align: left;
}

.value-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label {
  font-weight: bold;
}
