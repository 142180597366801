.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.table-container {
    margin: 0 auto;
}


.custom-label {
font-size: 14px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: 22px !important;
letter-spacing: 0.18px !important;
color:#54565A !important;
font-family: Helvetica Neue,Helvetica,Arial Nova,Arial,sans-serif;
}
.custom-labelInfo {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 21.98px !important;
    letter-spacing: 0.18px !important;
    margin-top:15px !important;
}
.custom-headers{
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 21.98px !important;
    letter-spacing: 0.18px !important;
    margin-top:15px !important;
    margin-bottom: 5px !important;
}
.custom-heading{
    font-size: 18px !important;
}
.custom-HeadingLabelInfo {
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 21.98px !important;
    letter-spacing: 0.18px !important;
    margin-top:15px !important;
    padding-bottom: 22px !important;
}
.required-label {
    color: blue;
    font-size: 16px;
    font-weight: bold;
    padding-top:15px;
}

.form-header {
color: #54565A;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.32px;
margin-top:20px;
}
.form-heading{
    border: solid 3px;
    background: #001b86;
    margin-bottom: 12px;
    text-align: center;
}


.error-message {
    color: red;
    font-size: 13px;
}
.red-border{
  width: 100%;
  height:50px;
  box-sizing: border-box;
  border: 1px solid red;
  padding: 5px 6px; 
  margin-bottom: 10px;
}
.red-border:focus {
    border: 0.5px solid red !important; 
    box-shadow: 0 0 0 0.5px red !important;
    border-color: red !important;
    border-radius: 0 !important; 
    outline:none !important; 
}

.red-borderDropdown {
    .css-1im77uy-control {
        border: 1px solid red !important;
        border-radius: 0px !important;
        height: 50px !important;
    }
    .css-cofman-control {
        border: 1.5px solid red !important;
        border-radius: 0px !important;
        height: 50px !important;
        box-shadow: 0 0 0 0.5px red !important;
        border-color: red !important;
        outline: none !important;
    }
}

.ucb-header {
    background-color: yellow;
}

.tableStyle {
    border: 4px solid #C0BFBD;
    border-collapse: collapse;
    width: 100% !important;
    overflow: auto !important;
}

.thStyle {
    color: white;
    background-color: #7A98B6;
    border: 2px solid #C0BFBD;
    text-align: center;
}

.tdStyle {
    color: #000000;
    background-color: white;
    border: 1px solid #C0BFBD;
    text-align: center;
    padding: 1px 6px 1px 6px;
}
.table-col-red td:nth-child(2) {
    color: red;
}

.customTdStyle {
    background-color: #A9A9A9;
    border: 1px solid #C0BFBD;
}

.H1Style {
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial Nova', 'Arial', sans-serif;
    font-weight: 700;
    background-color: #FFFFCC;
}

.H5Style {
    color: white;
    background-color: #003366;
    padding: 7px;
    margin-bottom: 50px;
}

body {
    font-family: Helvetica Neue,Helvetica,Arial Nova,Arial,sans-serif;
    background-color: #f4f4f4;
}
.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
}
label {
    display: block;
    font-weight: bold;
}
.input-text {
    width: 100%;
    padding: 5px 6px;
    margin-bottom: 10px;
    border: 2px solid #ccc;
    height: 50px;
    font-weight: 700;
    color: #53565a;
}
.input-text:focus {
    border: 0.5px solid #000000 !important; 
    box-shadow: 0 0 0 0.5px #000000 !important;
    border-color: #000000 !important;
    border-radius: 0 !important; 
    outline:none !important; 
}
.row {
    display: flex;
    justify-content: space-between;
}
.react-datepicker-wrapper{
    width: 100%;
}
input:read-only {
    background-color: #b7bcbd3b;
}


.loader-wrapper {
    width: 100%;
    height: 100%;
    z-index: 1030;
    background-color: rgba(0,0,0,0.5);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
  
    .loading-spinner {  
        position: absolute;
        width: 100%;
        top: 0px;
        left: 0px;
        height: 100vh;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        img {
            align-self: center;
            max-width: 90px;
            position: absolute;
            z-index: 1;
            margin-top: -8px;
          }
    }
    
  }

.gridHeader {
	background-color:#F2F2F2;
	height:30px;
	color:#000000;
	padding:10px 0px 0px 10px;
	font-weight:bold;
}
.gridHeaderHeight {
	height:40px;
	
}
.gridHeaderHeightFooterRow {
	height:40px;
	border:none;	
}
.gridHeaderFontSize {
	font-size:10px;
}
.gridHeader th {
	margin:0px;
}
// .gridHeader-col1 {
// 	border-top:1px solid #D2D2D2;
// 	border-bottom:1px solid #D2D2D2;
// 	border-right:1px solid #ffffff;
// 	padding-left:10px;
//     color: #666;
//     font-size: 12px;
//     font-weight: bold;
//     font-family: inherit;
//     font-style: inherit;
// }
// .gridHeader-col2 {
// 	border-top:1px solid #D2D2D2;
// 	border-bottom:1px solid #D2D2D2;
// 	border-right:none;
// 	padding-left:10px;
//     color: #666;
//     font-size: 12px;
//     font-weight: bold;
//     font-family: inherit;
//     font-style: inherit;
// }
.header-col {
  text-align: center;
  font-weight: 700;
  padding: 10px 16px;
  justify-content: center !important;
  align-items: center !important;
  background: #C0BFBD;
  font-size: 16px;
  font-family: inherit;
  font-style: inherit;
  color: #54565A;
  border-bottom: 4px solid #C0BFBD !important;
}
.gridHeader-col1, .gridHeader-col2 {
    font-weight: 700;
    height: 96px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    background: #C0BFBD;
    font-size: 16px;
    font-family: inherit;
    font-style: inherit;
    color: #54565A;
    border-bottom: 4px solid #C0BFBD !important;
}
.gridHeader-col1Sublevel {
    border-bottom:1px solid #ffffff;
    text-align:center;
}
.griddata-col1, .griddata-col2 {
	border-bottom:solid 1px #cccccc;
	height:50px;
	padding-left:10px;
}
.gridCenterAligh{
    text-align:center;
}
.griddataFontSize {
	font-size:10px;
}
.tablemerge {
	margin:0px;
	padding:0px
}
.inputAccountSelect {
	width:10px;
	height:5px;
	vertical-align:bottom;
}
.marginTop {
	margin-top:600px;
}
.paddingLeft100px {
	padding-left:100px;
}
.tabspacer {
	margin-left:25px;
}
.reportoutagesignin 
{
    padding-left:10px;
}
.griddataMP-col1, .griddataMP-col2 {
	height:50px;
	border-top:1px solid #D2D2D2;
	border-bottom:1px solid #D2D2D2;
	border-right:1px solid #ffffff;
	padding-left:10px;
}
.gridHeaderMP-col1, .gridHeaderMP-col2 {
	height:30px;
	font-weight:bold;
	background-color:#F2F2F2;
	border-top:1px solid #D2D2D2;
	border-bottom:1px solid #D2D2D2;
	border-right:1px solid #ffffff;
	padding-left:10px;
}
.gridFooter-col1 {
	border-top:none;
	border-bottom:none;
	border-right:none;
	padding-left:10px;
}
.gridFooter-col2 {
	border-top:none;
	border-bottom:none;
	border-right:none;
	padding-left:10px;
}
.gridFooter-col1, .gridFooter-col2 {
	height:30px;
	font-weight:bold;
	background-color:#F2F2F2;
    padding-left:4px !important;
}
.griddynamicdata-col {
    height: 96px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    font-size: 16px;
    font-family: inherit;
    font-style: inherit;
    border-bottom: 4px solid #C0BFBD !important;
}
.accordion-body{
    padding-top: 1.70rem !important;
    min-height: 78.2vh;
}
.table-design{
     padding: 18px;
     color:#54565A;
     font-weight:400;
     font-size:16px;
}
.table-additionalHeading{
    padding: 10px;
}
.footer-backToSelection{
    text-align: right; 
    margin-top: 25px;
    margin-bottom: 10px 
}
.css-13cymwt-control{
    border-radius: 0px !important;
    border-width: 2px !important;
    height:50px !important;
  }
  .NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet .NG-flexyBox .form-check .form-check-input {
    border-radius: 100px !important;
    border: 4px solid #fff !important;
    background: #fff;
    outline: 2px solid #D8D8D8;
}
.form-check-input:checked {
    border: 4px solid #fff !important;
    background: #438527 !important;
    outline: 2px solid #D8D8D8;
    box-shadow:none !important;
}
.form-check-input[type=checkbox] {
    border-radius: 0px !important;
}
.form-check-input[type=checkbox]:checked {
    box-shadow: 0 0 0 0.5px #000000 !important;
}
.form-check-input:checked[type=radio] {
    background: #438527 !important;
}
.css-1im77uy-control{
    height: 50px !important;
    border: 2px solid #d8d8d8 !important;
}
.css-cofman-control{
    height: 50px !important;
    border: 1px solid #000000 !important; 
    box-shadow: 0 0 0 0.5px #000000 !important;
    border-color: #000000 !important;
    border-radius: 0 !important; 
    outline:none !important; 
}
.css-1dimb5e-singleValue {
    font-weight: 700;
    color: #53565a !important;
}
.form-div{
    background: #fff;
    padding: 24px;
}
.div-accordian{
    padding: 0px 24px 24px 24px;
}
a.accordion-button{
    text-decoration: none;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion .NG-accordionHeader .accordion-button:not(.collapsed) {
    border-left: 2px solid #00aff0; 
}
.react-datepicker__calendar-icon{
    padding-top: 16px !important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    display: none;
}
.NG-mainHeader{
    background: #fff;
}

.login-labelInfo{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.42px;
    color: #001B86;
  }

.NG-mt-50 {
    margin-top: 50px;
}

.NG-mt-10 {
    margin-top: 10px;
}
.login-content {
    background: var(--Grey, #F0F0F0);
    width: 100vw;
    min-height: 76.2vh;
}

.NG-mt-25 {
    margin-top: 25px;
}
.label-color {
    color: #54565A;
}
.NGbr-0 {
    border: none;
    border-radius: 0;
}
.Ng-font-20 {
    font-size: 20px !important;
}
.NG-font-we0ght-600 {
    font-weight: 600 !important;
}
.ul{
    list-style: none;
    padding: 0;
    margin: 0;
    color: #001B86;
}

.li {
   display: flex;
   align-items: center;
}

.switch-label {
    color: #54565A !important;
    font-family: inherit !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    letter-spacing: 0.42px !important;
    margin-top: 24px;
    margin-bottom: 24px;
}

.view-type-link {
    color: #001B86;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.42px;
    text-decoration: none;
    cursor: pointer;
}
.disclaimer-section {
    padding: 48px 136px 0px 136px;

    p {
        color: #54565A;
        font-family: inherit;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.18px;

        a {
            color: #001B86;
            font-family: inherit;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.18px;
            text-decoration-line: underline;
        }

        .smallfont {
            color: #54565A;
            font-family: inherit;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.16px;
        }
    }
}

.disclaimer-heading {
    color: #54565A;
    font-family: inherit;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.72px;
}

@media (max-width: 1440px) {
    .label-pad-19 {
        padding-bottom: 19px;
    }
}

.img-header-link{
    height: 20px;
    width: 20px;
  }
  
  .ngcw-footer {
    background-color: #00148c;
    color: #fff;
    -webkit-font-smoothing: antialiased
  }
  
  .ngcw-footer * {
    box-sizing: border-box
  }
  
  .ngcw-footer__container {
    width: 100%;
    max-width: 1305px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto
  }
  
  @media (min-width: 595px) {
    .ngcw-footer__container {
        max-width:1325px;
        padding-left: 30px;
        padding-right: 30px
    }
  }
  
  .ngcw-footer-primary-links {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 35px 0 20px;
    width: 100%
  }
  
  @media (min-width: 595px) {
    .ngcw-footer-primary-links {
        flex-direction:row;
        padding: 25px 0 0
    }
  }
  
  @media (min-width: 900px) {
    .ngcw-footer-primary-links {
        align-items:center;
        padding: 35px 0 0
    }
  }
  
  @media (min-width: 595px) {
    .ngcw-footer-primary-links-main {
        padding-right:80px
    }
  }
  
  @media (min-width: 900px) {
    .ngcw-footer-primary-links-main {
        padding-right:0
    }
  }
  
  .ngcw-footer-primary-links .ngcw-footer__button {
    font-family: Helvetica Neue,Helvetica,Arial Nova,Arial,sans-serif;
    font-weight: 400;
    color: #fff;
    display: block;
    font-size: 14px;
    letter-spacing: .28px;
    line-height: 18px;
    padding-bottom: 20px;
    margin-top: 0;
    text-align: center;
    text-decoration: none
  }
  
  @media (min-width: 595px) {
    .ngcw-footer-primary-links .ngcw-footer__button {
        display:inline-block;
        font-size: 12px;
        padding: 5px 15px 5px 0
    }
  }
  
  .ngcw-footer-primary-links .ngcw-footer__button:hover {
    background-color: transparent;
    text-decoration: underline
  }
  
  .ngcw-footer-social {
    flex-shrink: 0;
    text-align: center
  }
  
  @media (min-width: 595px) {
    .ngcw-footer-social {
        margin:0 0 0 auto
    }
  }
  
  @media (min-width: 900px) {
    .ngcw-footer-social {
        align-items:center;
        display: flex
    }
  }
  
  .ngcw-footer-social-icon {
    border: 0;
    display: inline-block;
    height: 35px;
    margin: 0 5px 10px;
    max-width: 45px;
    min-height: auto;
    padding: 0;
    text-decoration: none
  }
  
  @media (min-width: 595px) {
    .ngcw-footer-social-icon {
        height:30px;
        margin: 0 8px;
        max-width: 35px
    }
  
    .ngcw-footer-social-icon:last-of-type {
        margin-right: 0
    }
  }
  
  @media (min-width: 900px) {
    .ngcw-footer-social-icon {
        height:25px;
        margin: 0 8px;
        max-width: 30px
    }
  }
  
  .ngcw-footer-social-icon .icon {
    height: 35px;
    max-width: 45px;
    pointer-events: none
  }
  
  @media (min-width: 595px) {
    .ngcw-footer-social-icon .icon {
        height:30px;
        max-width: 24px
    }
  
    .ngcw-footer-social-icon .icon-youtube {
        max-width: 30px
    }
  }
  
  @media (min-width: 900px) {
    .ngcw-footer-social-icon .icon {
        height:25px;
        max-width: 30px
    }
  }
  
  .ngcw-footer-secondary {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px
  }
  
  @media (min-width: 595px) {
    .ngcw-footer-secondary {
        align-items:flex-end;
        flex-direction: row;
        padding: 10px 0 40px
    }
  }
  
  @media (min-width: 900px) {
    .ngcw-footer-secondary {
        align-items:flex-end;
        padding: 15px 0 50px
    }
  }
  
  .ngcw-footer-secondary .google_translate_element {
    margin: auto
  }
  
  @media (min-width: 595px) {
    .ngcw-footer-secondary .google_translate_element {
        margin:0
    }
  }
  
  .ngcw-footer-secondary .google_translate_element * {
    border-radius: 0!important
  }
  
  .ngcw-footer-secondary .goog-te-gadget {
    display: block;
    font-size: 0;
    line-height: 0;
    max-height: 48px
  }
  
  .ngcw-footer-secondary .goog-te-gadget select.goog-te-combo {
    background-position: calc(100% - 15px) center;
    background-repeat: no-repeat;
    background-size: 5px 14px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: rgba(255,255,255,.1);
    border: 0;
    border-radius: 0!important;
    box-shadow: 0 2px 0 rgba(255,255,255,.5);
    color: #fff;
    display: block;
    font-family: Helvetica Neue,Helvetica,Arial Nova,Arial,sans-serif;
    font-size: 16px;
    max-height: 48px;
    letter-spacing: .28px;
    line-height: 18px;
    margin: auto;
    padding: 15px 25px 15px 20px
  }
  
  .ngcw-footer-secondary .goog-te-gadget select.goog-te-combo * {
    color: #000
  }
  
  .ngcw-footer-secondary .goog-te-gadget select.goog-te-combo::-ms-expand {
    display: none
  }
  
  @media (min-width: 595px) {
    .ngcw-footer-secondary .goog-te-gadget select.goog-te-combo {
        font-size:12px;
        letter-spacing: .24px;
        margin: 0;
        padding: 10px
    }
  }
  
  @media (min-width: 900px) {
    .ngcw-footer-secondary .goog-te-gadget select.goog-te-combo {
        padding:10px 20px
    }
  }
  
  .form-component .wFormContainer .wfHideSubmit,.form-component .wFormContainer .wfPage,.ngcw-footer-secondary .goog-te-gadget .goog-logo-link {
    display: none
  }
  
  .ngcw-footer .copyright {
    color: #fff;
    font-family: Helvetica Neue,Helvetica,Arial Nova,Arial,sans-serif;
    font-size: 10px;
    letter-spacing: .2px;
    line-height: 18px;
    margin: 30px 10px 0;
    text-align: center
  }
  
  @media (min-width: 595px) {
    .ngcw-footer .copyright {
        margin:0 0 0 auto;
        padding-left: 20px
    }
  }
  
  .ngcw-footer .a11y-hide {
    position: absolute!important;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    -webkit-clip-path: inset(0 100% 100% 0);
    clip-path: inset(0 100% 100% 0);
    border: 0;
    top: 0
  }
  .no-wrap-menuitem{
    white-space: nowrap;
    padding: 8px 16px 8px 16px
  }
 
  .login-ng-image{
    width: 181px;
    height: 41px;
  }
  .header-menu-item{
    float: inline-end;
    padding-bottom: 8px;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .logout-btn{
    border: 1px solid #DDDDDE;
    padding: 7.5px 16px 7.5px 16px;
    cursor: pointer;
  }
  .logout-menuitem{
    padding: 4px 0px 0px 4px;
    margin-left: -5px;
  }
  .no-wrap-menuitem-second{
    white-space: nowrap;
    padding: 8px 16px 8px 8px
  }
  .imgVector{
    height: 26px;
    width: 26px;
    margin-left: 10px;
    margin-top: 7px;
  }
  .imgVectorDiv{
    height: 46px;
    width: 46px;
    background: #D8D8D8;
    border-radius: 50px;
    float: left;
}
.imgUserDiv{
    padding-bottom: 20px;
}
.user-name{
    white-space: nowrap;
    font-size: 20px !important;
    line-height: 24.42px !important;
    padding-top: 17px;
    padding-left: 12px;
    color: #54565A;
}
.custom-file{
  input[type=file]{
    width: 100%;
  }
}
.gray-border{
  border: 1px solid #d0cccc;
}