.paginatorLinks .disabled-link {
    cursor: not-allowed;
    display: none;
}

.paginatorLinks a {
    text-decoration: none;
    color: var(--Blue, #4BAEEA);
    outline: none;
    font-size: 8pt;
    line-height: 1em;
    margin: 0px 9px;
    cursor: pointer;
    font-weight: bold;
}

.paginatorLinks a.active {
    color: #000000;
}

.paginatorLinks a:hover {
    text-decoration: underline;
}
.show-entities{
    color: var(--Black, #000);
    text-align: center;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28px;
}
.even-row {
    background: linear-gradient(0deg, var(--White, #FFF) 0%, var(--White, #FFF) 100%), var(--Neutral-1, #54565A);
}

.odd-row {
    background: linear-gradient(0deg, var(--Grey, #F0F0F0) 0%, var(--Grey, #F0F0F0) 100%), var(--Neutral-1, #54565A);
}
.paginatorLinks-top{
    border: 4px solid #C0BFBD;
    border-bottom: 0;
    height: 48px;
    padding: 10px 16px 10px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
.paginatorLinks-bottom{
    border: 4px solid #C0BFBD;
    border-top: 0;
    height: 48px;
    padding: 10px 16px 10px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
.table-section{
    width: 100%;
    overflow: auto;
    border: 4px solid #C0BFBD;
}