body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scrollTable
{
  height: 500px;
  overflow-y: scroll;
  display: block;
}
.fullwidth-components {
  width: calc(100% - 232px);
}
.ng-mt-4 {
  margin-top: 4px;
}
 
.ng-mt-8 {
  margin-top: 8px;
}
.ng-mb-4{
  margin-bottom: 6px;
}
.pt-0 {
  padding-top: 0px;
}
.ng-mb-0 {
  margin-bottom: 0px !important;
}
.ng-text-right {
  text-align: right;
}