.paginatorLinks .disabled-link {
    cursor: not-allowed;
    display: none;
}

.paginatorLinks a {
    text-decoration: none;
    color: var(--Blue, #4BAEEA);
    outline: none;
    font-size: 7pt;
    line-height: 1em;
    margin: 0px 5px;
    cursor: pointer;
    font-weight: bold;
}

.paginatorLinks a.active {
    color: #000000;
}

.paginatorLinks a:hover {
    text-decoration: underline;
}
.show-entities{
    color: var(--Black, #000);
    text-align: center;
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28px;
}
.row-wd-100{
    width: 100%;
}
.form-label-heading{
    color: #54565A !important;
    font-family: inherit !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    letter-spacing: 0.18px !important;
    margin-top: 20px;
    margin-bottom: 9px !important;
}